import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppTheme, ThemeService } from '@services/theme/theme.service';
import { Subscription } from 'rxjs';
import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  imports: [MatSlideToggleModule, IconComponent],
  selector: 'pd-theme-toggle',
  template: `<mat-slide-toggle
    color="primary"
    (click)="$event.stopPropagation()"
    (change)="toggleTheme()"
    [checked]="currentTheme.isDarkTheme()">
    <pd-icon class="ml-6 text-lg" [icon]="currentTheme.isDarkTheme() ? 'moon' : 'sun'"></pd-icon>
  </mat-slide-toggle>`,
})
export class ThemeToggleComponent implements OnInit, OnDestroy {
  public currentTheme: AppTheme;
  private themeSub: Subscription;
  public constructor(public themeService: ThemeService) {}

  public ngOnInit(): void {
    this.themeSub = this.themeService.currentTheme$.subscribe((currentTheme) => {
      this.currentTheme = currentTheme;
    });
  }

  public ngOnDestroy(): void {
    this.themeSub?.unsubscribe();
  }

  public toggleTheme(): void {
    this.themeService.toggleTheme();
  }
}
